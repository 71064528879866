import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './styles/basicLearnMorePanel.scss';



const SpeakWithAdmissions = ({ menuType, className }) => {
    if(menuType === "bottom") {
        return (
            <div id="learnMoreBottomPanel">
                <Row className="p-3 p-xl-4 p-md-5 p-xxl-5">
                    <Col>
                        <h4 className="info-title mb-md-5">Speak with an Admissions Advisor</h4>
                        <p className="info-text mb-md-4">Scheduling a voice or video call with an Admissions Advisor is a great place to begin. During the call you can ask questions about Meridian's academic structure, degree programs, and concentrations, as well as understand the admissions process and timeline.</p>
                        <Button variant="primary" href="/admissions/graduate-admissions/schedule-with-admissions">schedule a call »</Button>
                    </Col>
                </Row>
            </div>
        )
    }
    if(menuType === "bottom-blue") {
        return (
            <div id="learnMoreBlueBottomPanel">
                <Row className="p-0 p-lg-5 p-md-4">
                    <Col className="p-5 p-md-2 p-lg-0 col p-md-0">
                        <h4 className="info-title mb-xl-4 mb-3">Learn More</h4>
                        <p className="info-text mb-3 mb-xl-5">Interested in learning more about the programs at Meridian?</p>
                        <Button variant="primary" href="/admissions/graduate-admissions/schedule-with-admissions" className="me-3 mb-3 mb-xxl-0">Contact An Advisor »</Button>
                        <Button variant="outline" href="/admissions/graduate-admissions/info-sessions-and-events" className="mb-md-1 mb-lg-0">Attend an Info Session »</Button>
                    </Col>
                </Row>
            </div>
        )
    }
    if(menuType === "bottom-green") {
        return (
            <div id="learnMoreGreenBottomPanel" className={`${className}`}>
                <Row className="p-4 px-xxl-4 p-md-4 py-lg-5 py-xxl-5 row">
                    <Col>
                        <h4 className="info-title mb-xxl-5 mb-xl-4">Learn More</h4>
                        <p className="info-text mb-md-4">Interested in learning more about the programs at Meridian?</p>
                        <Button variant="primary" href="/admissions/graduate-admissions/schedule-with-admissions" className="me-3 mb-3 mb-xxl-0">Contact An Advisor »</Button>
                        <Button variant="outline" href="/admissions/graduate-admissions/info-sessions-and-events">Attend an Info Session »</Button>
                    </Col>
                </Row>
            </div>
        )
    } 
    if(menuType === "bottom-grey") {
        return (
            <div id="learnMoreGreyBottomPanel">
                <Row className="p-4 px-xxl-4 p-md-4 py-lg-5 py-xxl-4">
                    <Col>
                        <h4 className="info-title mb-xl-3 mb-4">Start Your Future at Meridian</h4>
                        <p className="info-text mb-3">Interested in learning more about the programs at Meridian?</p>
                        <ul>
                            <li><a href="/admissions/graduate-admissions/request-for-information" className="btn btn-link">Request Information</a></li>
                            <li><a href="/admissions/graduate-admissions/info-sessions-and-events" className="btn btn-link">Attend Online Info Session</a></li>
                            <li><a href="/admissions/graduate-admissions/schedule-with-admissions" className="btn btn-link">Schedule a Call</a></li>
                            <li className="mb-0"><a href="/admissions/graduate-admissions/apply-online" className="btn btn-link">Start Application</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
        )
    }    
    else {
        return (
            <div id="learnMorePanel">
                <Row className="my-3 my-lg-5 white-bg">
                    <Col className="p-3 p-lg-4">
                        <h4 className="info-title">Speak with an Admissions Advisor</h4>
                        <p className="info-text">Scheduling a voice or video call with an Admissions Advisor is a great place to begin. During the call you can ask questions about Meridian's academic structure, degree programs, and concentrations, as well as understand the admissions process and timeline.</p>
                        <Button className="btn-fw" variant="primary" href="/admissions/graduate-admissions/schedule-with-admissions">schedule a call »</Button>
                    </Col>
                </Row>
            </div>
        )
    }
} 

export default SpeakWithAdmissions